import { useGAEventStore } from '@/store/GAEventTracking'
import { useWindowSize } from '@vueuse/core'
import type { App } from 'vue'
import { VisibilityState } from './observe-visibility'
import { deepEqual } from '@/helpers/utils'

const thresholdForViewport = 0.5

export default {
  install: (app: App) => {
    const store = useGAEventStore()
    const settings = (el: any) => ({
      intersection: { threshold: el._vue_visibilityState_threshold ?? 0.5 },
      throttle: 2000,
      once: true,
      callback: (isVisible: boolean) => {
        if (!isVisible) return
        let value = el._vue_gaSectionView_Value
        if (typeof value === 'string') {
          // value can be string as shorthand value
          value = {
            sectionContent: value,
          }
        }
        store.pushSectionViewEvent(el, value)
      },
    })
    app.directive('ga-section-view', {
      mounted(el, { value }) {
        const { height: winHeight } = useWindowSize()
        const { height } = useElementSize(el)
        watchDebounced(
          [height, winHeight],
          () => {
            // console.log(value, height.value, el.dataset)
            if (height.value > winHeight.value) {
              const newThreshold = (winHeight.value * thresholdForViewport) / height.value
              // console.log(value, 'new threshold', newThreshold)
              el._vue_visibilityState_threshold = newThreshold
              const state = el._vue_visibilityState
              state.createObserver(settings(el))
            }
          },
          { debounce: 100, maxWait: 400 }
        )

        el._vue_gaSectionView_Value = value
        el._vue_visibilityState = new VisibilityState(el, settings(el))
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        el._vue_gaSectionView_Value = value
        const state = el._vue_visibilityState
        if (state) {
          state.createObserver(settings(el))
        }
      },
      unmounted(el) {
        const state = el._vue_visibilityState

        if (state) {
          state.destroyObserver()
          delete el._vue_visibilityState
        }
      },
    })
  },
}
