import { AsyncComponentLoader, Component, defineAsyncComponent, defineComponent, h, onMounted, ref } from 'vue'

type ComponentResolver = (component: Component) => void

export const lazyLoadComponent = ({
  comp,
  errorComp,
  delay = 0,
  timeout,
}: {
  comp: AsyncComponentLoader
  errorComp?: Component
  delay?: number
  timeout?: number
}) => {
  let resolveComponent: ComponentResolver

  return defineAsyncComponent({
    loader: () => {
      return new Promise((resolve) => {
        resolveComponent = resolve as ComponentResolver
      })
    },
    // A component to use while the async component is loading
    loadingComponent: defineComponent({
      setup() {
        const elRef = ref()

        async function loadComponent() {
          const component = await comp()
          resolveComponent(component)
        }

        onMounted(async () => {
          // We immediately load the component if
          // IntersectionObserver is not supported
          if (!('IntersectionObserver' in window)) {
            await loadComponent()
            return
          }
          const observer = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting) {
              observer.unobserve(elRef.value)
              loadComponent()
            }
          })
          observer.observe(elRef.value)
        })

        return () => {
          return h('div', { ref: elRef, style: { height: '400px' } }, '')
        }
      },
    }),
    // Delay before showing the loading component. Default: 200ms.
    delay,
    // A component to use if the load fails
    errorComponent: errorComp,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout,
  })
}
