// import { getUserLocale } from '@/i18n'
import Menu from './menu'
import Error from '@/views/ErrorView.vue'

const recursiveFilteringRoutes = (menu: RouteRecordRaw[]) =>
  menu
    .filter((c) => !c.path.startsWith('__'))
    .map((c) => {
      const cloned = { ...c }
      if (cloned.children) {
        const list = recursiveFilteringRoutes(cloned.children)
        cloned.children = list.length ? list : undefined
      }
      return cloned
    })

const recursiveFilteringMenu = (menu: RouteRecordRaw[]) =>
  menu.reduce((prev, current) => {
    const cloned = { ...current }
    if (cloned.children) {
      const list = recursiveFilteringMenu(cloned.children)
      cloned.children = list.length ? list : undefined
    }
    if (cloned.meta?.menuTitle && !cloned.meta?.hideOnMenu) prev.push(cloned)
    else if (cloned.children) {
      const list = recursiveFilteringMenu(cloned.children)
      prev = prev.concat(list)
    }
    return prev
  }, [] as RouteRecordRaw[])

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/:locale([a-z]{2})',
  //   component: EmptyRouterView,
  //   props: true,
  //   children: [
  {
    path: '',
    name: 'main',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      seo: {
        title: 'Official Website',
        description:
          'Live it up in MapleStory, the original side-scrolling MMORPG! Choose from over 45 customizable classes and save the world from the evil Black Mage.',
      },
      gaPageType: 'homepage',
    },
  },
  ...recursiveFilteringRoutes(Menu),
  // ],
  // },
  {
    path: '/:catchAll(.*)',
    redirect: 'error',
    // redirect: (to) => {
    //   const paths = to.path.slice(1).split('/')
    //   let path = ''

    //   const locale = getUserLocale()
    //   if (paths[0].length !== 2) {
    //     path = `/${locale}${to.fullPath}`
    //   } else {
    //     path = `/${locale}` // default redirection page
    //   }
    //   return {
    //     path,
    //   }
    // },
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {
      seo: {
        title: 'Error',
      },
    },
  },
  {
    path: '/error-general',
    name: 'error-general',
    component: Error,
    meta: {
      seo: {
        title: 'Error',
      },
    },
  },
]

export const MenuList = recursiveFilteringMenu(Menu)

export const FlatMenuList = MenuList.reduce((prev, cur) => {
  if (cur.children) prev = prev.concat(cur.children)
  else prev.push(cur)
  return prev
}, [] as RouteRecordRaw[])

export default routes
