import { intersectObserver } from '@/helpers/intersectObserver'
import { useGAEventStore } from '@/store/GAEventTracking'

function addTrackingEvent(ele: HTMLElement, cb: () => void) {
  if (ele) {
    ele.addEventListener('click', cb)
  }
}
export const addGnbGaEvents = () => {
  const gaEvent = useGAEventStore()

  const gnbNav = () => {
    const sectionNumber = 0
    const sectionName = 'Nexon GNB'
    const commonTrackingInfo = {
      sectionNumber,
      sectionName,
      interaction: 'button_click',
    }
    const $globalNav = document.querySelector('#globalNavigation') as HTMLElement
    intersectObserver({
      element: $globalNav,
      callback: [
        () => {
          gaEvent.pushActionEvent({
            ...commonTrackingInfo,
            interaction: 'section_view',
          })
        },
      ],
    })
    $globalNav.addEventListener('click', (e) => {
      if ((e.target as HTMLElement).closest('.nav-logo')) {
        gaEvent.pushActionEvent({
          ...commonTrackingInfo,
          sectionContent: 'Nexon Logo',
        })
      }
      if ((e.target as HTMLElement).closest('.nav-account.login')) {
        gaEvent.pushActionEvent({
          ...commonTrackingInfo,
          sectionContent: 'Login',
        })
      }
      if ((e.target as HTMLElement).closest('.nav-account.create')) {
        gaEvent.pushActionEvent({
          ...commonTrackingInfo,
          sectionContent: 'Create Account',
        })
      }
      //  Side Mobile Menu
      if ((e.target as HTMLElement).closest('.sidebar-account-content .login')) {
        gaEvent.pushActionEvent({
          ...commonTrackingInfo,
          sectionContent: 'Login',
        })
      }
      if ((e.target as HTMLElement).closest('.sidebar-account-content .create-account')) {
        gaEvent.pushActionEvent({
          ...commonTrackingInfo,
          sectionContent: 'Create Account',
        })
      }
    })
  }

  const gnbFooter = () => {
    const sectionNumber = 99
    const sectionName = 'Footer'
    const commonTrackingInfo = {
      sectionNumber,
      sectionName,
      interaction: 'link_click',
    }
    const $globalFooter = document.querySelector('#globalFooter') as HTMLElement

    addTrackingEvent($globalFooter.querySelector('.footer-link.careers') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Careers',
      })
    })

    addTrackingEvent($globalFooter.querySelector('.footer-link.aBOUTNEXON') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'About Nexon',
      })
    })

    addTrackingEvent($globalFooter.querySelector('.footer-link.sUPPORT') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Support',
      })
    })

    addTrackingEvent($globalFooter.querySelector('.footer-link.ourTeam') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Our team',
      })
    })

    addTrackingEvent($globalFooter.querySelector('.footer-link.press') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Press',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.footer-link.investorRelations') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Investor Relations',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.footer-link.privacy') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Privacy Policy',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.footer-link.privacyPolicyMatrix') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Privacy Policy Matrix',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.footer-link.legalDoc') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Legal Documentation',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.footer-link.cookiePolicy') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Cookie Policy',
      })
    })
    addTrackingEvent($globalFooter.querySelector('.ot-sdk-show-settings') as HTMLElement, () => {
      gaEvent.pushActionEvent({
        ...commonTrackingInfo,
        sectionContent: 'Cookie Settings',
      })
    })
  }

  const init = () => {
    setTimeout(() => {
      gnbNav()
      gnbFooter()
    }, 1000)
  }
  init()
}
