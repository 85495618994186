<script setup lang="ts">
  import { useLightBoxStore } from '@/store/LightBox'
  import { watchEffect } from 'vue'
  const lb = useLightBoxStore()
  watchEffect(() => {
    if (lb.isActive === true) {
      window.addEventListener('keyup', onEscapeKeyUp)
    }
    if (lb.isActive === false) {
      window.removeEventListener('keyup', onEscapeKeyUp)
    }
  })
  function onEscapeKeyUp({ code }: { code: string }) {
    if (code === 'Escape') {
      lb.closeLightBox()
    }
  }
</script>
<template>
  <div
    class="c-lightbox"
    :class="[{ active: lb.isActive, show: lb.visibility, forComponent: lb.items[0]?.component }, lb.option.class]"
  >
    <div class="c-lightbox__inner-box-wrapper">
      <div class="c-lightbox__inner-box">
        <div class="c-lightbox__close-icon" @click="lb.closeLightBox()">X</div>
        <div class="c-lightbox__item">
          <component :is="lb.items[0]?.component" v-if="lb.items[0]?.component" />
          <iframe
            v-if="lb.items[0]?.ytVideo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            frameborder="0"
            height="315"
            :src="`https://www.youtube.com/embed/${lb.items[0].ytVideo}`"
            title="YouTube video player"
            width="560"
          ></iframe>
        </div>
        <!-- This trigger onMount two times even tho there is only one item in lb.items = []. Fix this later -->
        <!-- <div v-for="(item, index) in lb.items" :key="`c-lightbox__item-${index}`" class="c-lightbox__item">
          <component :is="{ ...item.component }" v-if="item.component" />
          <iframe
            v-if="item.ytVideo"
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/${item.ytVideo}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  :global(.lightbox-active) {
    /* overflow-y: hidden; */
    height: 100%;
  }
  .c-lightbox {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(black, 0.7);
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
    display: none;
    opacity: 0;
    transition: 0.3s opacity;
    z-index: 100;
    &.active {
      pointer-events: auto;
      display: block;
    }
    &.show {
      opacity: 1;
      .c-lightbox__inner-box {
        transform: translateY(0);
      }
    }
    &.forComponent {
      .c-lightbox__item {
        padding: 10px 20px;
      }
    }
  }

  .c-lightbox__inner-box-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    transform: translateY(-20px);
  }
  .c-lightbox__close-icon {
    z-index: 1;
    color: white;
    font-size: 30px;
    position: absolute;
    right: -35px;
    top: -5px;
    cursor: pointer;
  }
  .c-lightbox__inner-box {
    width: 900px;
    min-height: 600px;
    transition: 0.3s transform;
    transform: translateY(-20%);
    background: black;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .c-lightbox__item {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media #{$tablet} {
    .c-lightbox__inner-box-wrapper {
    }
    .c-lightbox__inner-box {
      // margin-top: 200px;
      width: 95%;
    }
    .c-lightbox__close-icon {
      margin-left: auto;
      margin-right: 24px;
      right: -15px;
      top: -40px;
      z-index: 10;
    }
  }
</style>
