import { defineStore } from 'pinia'
import type { Component } from 'vue'

interface ILightBoxItems {
  ytVideo?: string
  img?: string
  component?: Component
}
interface ILightBoxOption {
  class?: string
}
interface IComponentProps {
  [key: string]: any
}
interface IOpenLightBoxArg {
  items: Array<ILightBoxItems>
  componentProp?: IComponentProps
  option?: ILightBoxOption
}
interface ILightBoxState {
  isActive: boolean
  visibility: boolean
  index: number
  option: ILightBoxOption
  items: Array<ILightBoxItems>
  componentProp: IComponentProps
}
export const useLightBoxStore = defineStore('LightBox', {
  state: (): ILightBoxState => ({
    isActive: false,
    visibility: false,
    index: 0,
    items: [],
    componentProp: {},
    option: {
      class: '',
    },
  }),
  actions: {
    openLightBox(arg: IOpenLightBoxArg) {
      this.isActive = true
      this.items = arg.items
      this.componentProp = arg.componentProp ? arg.componentProp : {}
      this.option = {
        class: arg.option?.class,
      }
      setTimeout(() => {
        this.visibility = true
      }, 50)
      document.body.classList.add('lightbox-active')
    },
    closeLightBox() {
      this.visibility = false
      setTimeout(() => {
        this.isActive = false
        this.index = 0
        this.items = []
        this.componentProp = {}
        this.option = {
          class: '',
        }
      }, 400)
      document.body.classList.remove('lightbox-active')
    },
    // For sliding section. (Multiple images, multiple youtube.)
    // nextScreen() {
    //   const t = this
    //   if (t.items.length > t.index) {
    //     this.index++
    //   }
    // },
    // prevScreen() {
    //   if (this.index != 0) {
    //     this.index--
    //   }
    // },
  },
})
