interface IIntersectObserver {
  element: HTMLElement
  callback: (() => void)[] // 2nd function for none-intersect and clearAfterIntersect = false
  clearAfterIntersect?: boolean
  option?: object
}
export const intersectObserver = ({
  element,
  callback,
  clearAfterIntersect = true,
  option = { threshold: 0.3 },
}: IIntersectObserver) => {
  // const arg = { ...defaultSetting, ..._arg }
  // Set observer
  const observer = new IntersectionObserver(([entry]) => {
    // After intersect, call first callback
    if (entry.isIntersecting) {
      if (callback[0]) {
        callback[0]()
      }

      if (clearAfterIntersect) {
        observer.unobserve(element)
      }
    }
    // After none-Intersect, call 2ndcallback
    if (!entry.isIntersecting && callback[1]) {
      callback[1]()
    }
  }, option)
  observer.observe(element)
  return observer
}
