<template>
  <v-fade-transition>
    <div v-if="show" class="scroll-to-top-btn" @click="y = 0">
      <i-local-mobile-arrow-up />
    </div>
  </v-fade-transition>
</template>
<script lang="ts" setup>
import { useWindowScroll, watchDebounced, useDebounceFn } from '@vueuse/core'
const { mobile } = useDisplay()
const { y } = useWindowScroll({ behavior: 'smooth' })
const below = ref(false)
const showTimer = ref(false)

const show = computed(() => mobile.value && below.value && showTimer.value)

watchDebounced(
  y,
  () => {
    below.value = y.value > window.innerHeight // if scroll Y position is below than the window's height
  },
  { debounce: 100 }
)

let timeout = null as any

const handleScroll = useDebounceFn(() => {
  // console.log('scroll end')
  showTimer.value = true
  if (timeout != null) clearTimeout(timeout)
  timeout = setTimeout(() => {
    showTimer.value = false
  }, 5000)
}, 200)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<style lang="scss" scoped>
.scroll-to-top-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
  :deep(svg) {
    width: 55px;
    height: 55px;
  }
}
</style>
