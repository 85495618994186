import { defineStore } from 'pinia'

interface UserState {
  isLoggedIn: boolean | null
  nexonTag: string
}
export const useUserStore = defineStore('User', {
  state: (): UserState => ({
    isLoggedIn: null,
    nexonTag: '',
  }),
  actions: {
    getUserInfo() {
      let numberOfcheck = 0
      const checkUserStatus = setInterval(() => {
        // window.Gnb.$store.state.isLoggedIn
        if (window.nexon.sso !== undefined || numberOfcheck > 50) {
          clearInterval(checkUserStatus)
          this.isLoggedIn = window.nexon.sso.isLoggedIn
          this.nexonTag = window.nexon.sso.nexonTag ? window.nexon.sso.nexonTag : ''
        }
        numberOfcheck++
      }, 500)
    },
  },
})
