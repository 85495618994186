import { deepEqual } from '@/helpers/utils'
import { useGAEventStore } from '@/store/GAEventTracking'
import { useElementHover, watchOnce } from '@vueuse/core'
import type { App } from 'vue'

export default {
  install: (app: App) => {
    const store = useGAEventStore()

    app.directive('ga-hover', {
      mounted(el, { arg, value }) {
        if (!value) return

        const delayEnter = arg == undefined && isNaN(Number(arg)) ? 250 : Number(arg)
        const isHovered = useElementHover(el, { delayEnter })

        el._vue_gaHover_Value = value

        watchOnce(isHovered, () => {
          let value = el._vue_gaHover_Value
          if (typeof value === 'string') {
            // value can be a string as a shorthand value
            value = {
              sectionContent: value,
            }
          }
          store.pushActionEvent(
            {
              interaction: 'button_click', // default value
              ...value,
            },
            el
          )
        })
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        el._vue_gaHover_Value = value
      },
    })
  },
}
