import placeHolderImage from '@/assets/imgs/common/directive/lazyimage/lazyimage-default.webp'
import { intersectObserver } from '@/helpers/intersectObserver'
import type { App } from 'vue'
export default {
  install: (app: App) => {
    app.directive('lazyimage', {
      mounted(el) {
        const source = el.getAttribute('src')
        el.setAttribute('src', placeHolderImage)
        intersectObserver({
          element: el,
          callback: [
            () => {
              el.setAttribute('src', source)
              el.style.display = 'inline-block'
            },
          ],
          clearAfterIntersect: true,
          option: { threshold: 0.1 },
        })
      },
    })
  },
}
