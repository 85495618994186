<script setup lang="ts">
const { mobile } = useDisplay()
</script>
<template>
  <header data-event="General Elements" data-section-number="0" data-section-name="Game GNB">
    <MobileBar v-if="mobile" />
    <DesktopBar v-else />
  </header>
</template>

<style lang="scss" scoped>
header {
  width: 100%;
  font-weight: 400;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--nav-bg-c);
  color: var(--nav-font-c);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
