import { cdnApi } from '@/helpers/api'
import { useAsyncState } from '@vueuse/core'
import { defineStore } from 'pinia'

interface IOverlayCtaData {
  isActive: boolean
  link: string
  micrositeName: string
  micrositePhase: string
  overlayRestImage: string
  overlayHoverImage: string
  arrowRestImage: string
  arrowHoverImage: string
}

export const useOverlayCtaStore = defineStore('OverlayCtaStore', () => {
  const {
    state,
    isReady,
    execute: patch,
  } = useAsyncState(
    () => cdnApi.get('/overlay-cta').then(({ data }) => data as IOverlayCtaData),
    {
      isActive: false,
      link: '',
      micrositeName: '',
      micrositePhase: '1',
      overlayHoverImage: '',
      overlayRestImage: '',
      arrowHoverImage: '',
      arrowRestImage: '',
    },
    { resetOnExecute: false }
  )
  return {
    state,
    isReady,
    patch,
  }
})
