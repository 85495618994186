<template>
  <div class="media-platforms" v-ga-section-view data-section-number="99" data-section-name="Footer">
    <ul class="box">
      <!-- <li>Join width us!</li> -->
      <li>
        <a
          href="//www.facebook.com/maplestory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Facebook',
          }"
          ><i-simple-icons-facebook
        /></a>
      </li>
      <li>
        <a
          href="//twitter.com/maplestory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Twitter',
          }"
          ><i-simple-icons-x
        /></a>
      </li>
      <li>
        <a
          href="//www.youtube.com/user/MapleStory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Youtube',
          }"
          ><i-simple-icons-youtube
        /></a>
      </li>
      <li>
        <a
          href="//www.instagram.com/maplestory/"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Instagram',
          }"
          ><i-simple-icons-instagram
        /></a>
      </li>
      <li>
        <a
          href="//www.twitch.tv/maplestory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Twitch',
          }"
          ><i-simple-icons-twitch
        /></a>
      </li>
      <li>
        <a
          href="//discord.gg/maplestory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Discord',
          }"
          ><i-simple-icons-discord
        /></a>
      </li>
      <li>
        <a
          href="//www.tiktok.com/@maplestory"
          target="_blank"
          v-ga-event="{
            interaction: 'link_click',
            sectionContent: 'Tiktok',
          }"
          ><i-simple-icons-tiktok
        /></a>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts"></script>

<style lang="scss" scoped>
.media-platforms {
  background: #191a1c;
  .box {
    display: table;
    margin: 0 auto;
    list-style-type: none;
    border-radius: 50px;
    color: white;
    text-align: center;
    padding: 34px 0 0;
    li {
      display: table-cell;
      vertical-align: middle;
      // text-transform: uppercase;
      // font-size: 1.5vw;
      // font-weight: 700;
      display: inline-block;
      padding: 0 16px 10px;
      svg {
        width: 25px;
        height: 100%;
        vertical-align: middle;
        transition: color 0.2s;
        cursor: pointer;
        &:hover {
          color: var(--accent-color1);
        }
      }
    }
  }
}
@media #{$mobileSmall} {
  .media-platforms {
    .box {
      li {
        padding: 0 12px 10px;
        svg {
          width: 24px;
        }
      }
    }
  }
}
</style>
