// import Util from "./Util"

let opts = {
  debounce: 200,
  views: [
    {
      view: 'mobile',
      windowWidth: 600,
      fontSize: 18,
      minFontSize: 1,
    },
    {
      view: 'tablet',
      windowWidth: 1024,
      fontSize: 38,
      minFontSize: 1,
    },
    {
      view: 'desktop',
      windowWidth: 1400,
      fontSize: 18,
      minFontSize: 12,
      maxFontSize: 25,
    },
  ],
}

export const init = (settings = null) => {
  if (settings) opts = settings
  detectView()
  window.addEventListener('resize', detectView)
  // if (Util.isTouchDevice()) Util.addClass(document.body, 'istouch')
}

const detectView = () => {
  let active = false
  const body = document.body
  setTimeout(() => {
    if (active) return false
    active = true
    const views = opts.views.sort((a, b) => b.windowWidth - a.windowWidth)
    const width = window.innerWidth
    let view = views[0]
    for (let i = 0, c = views.length; i < c; i++) {
      // Util.removeClass(body, views[i].view);
      body.classList.remove(views[i].view)
      if (width <= views[i].windowWidth) view = views[i]
    }
    // Util.addClass(body, view.view)
    body.classList.add(view.view)
    let size = ((view.fontSize * width) / view.windowWidth).toFixed(1)
    if (size < view.minFontSize) size = view.minFontSize
    if (view.maxFontSize && size > view.maxFontSize) size = view.maxFontSize
    document.documentElement.style.fontSize = size + 'px'
    active = false
  }, opts.debounce)
}
