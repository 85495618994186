<script setup lang="ts">
import { useElementHover } from '@vueuse/core'

const props = defineProps({
  menu: {
    type: Object as PropType<RouteRecordRaw>,
    required: true,
  },
  activeMenuName: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['active-menu'])

const menuRef = ref()
const isHovered = useElementHover(menuRef, { delayEnter: 100 })
watch(isHovered, (value) => {
  if (value) emits('active-menu', props.menu.name)
})
watch(
  () => props.activeMenuName,
  (value) => {
    if (isHovered.value && props.menu.name !== value) {
      isHovered.value = false // this is for touch device
    }
  }
)
</script>

<template>
  <div ref="menuRef" class="main-menu-desktop" :class="{ hover: isHovered }" @click="isHovered = true">
    <template v-if="menu.children">
      <div class="main-menu-button">
        {{ menu.meta?.menuTitle }}
        <i-local-nav-arrow />
      </div>
      <Transition name="bounce-dropdown">
        <div v-show="isHovered" class="sub-menu-container" @click.stop="isHovered = false">
          <div class="sub-menu-box">
            <template v-for="sub in menu.children" :key="sub.name">
              <template v-if="sub.children">
                <MenuLink
                  v-for="subsub in sub.children"
                  :key="subsub.name"
                  :menu="subsub"
                  :parent-name="menu.meta?.menuTitle"
                  class="sub-menu-button"
                />
              </template>
              <MenuLink v-else :menu="sub" :parent-name="menu.meta?.menuTitle" class="sub-menu-button" />
            </template>
          </div>
        </div>
      </Transition>
    </template>
    <MenuLink v-else :menu="menu" class="main-menu-button" />
  </div>
</template>
<style lang="scss" scoped>
.main-menu-desktop {
  display: inline-flex;
  cursor: pointer;
  padding: 13px 0 14px;
  position: relative;
  user-select: none;
}
.main-menu-button {
  display: inline-flex;
  align-items: center;
  padding: 12px 20px;
  line-height: 17px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 10px;
  color: var(--nav-font-c);
  transition: background-color 0.2s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  svg {
    margin-left: 10px;
    margin-right: -8px;
  }
  // min-height: 33px;
  .hover & {
    background-color: #3e3d3d;
    svg {
      fill: #fff; // nav-arrow svg
    }
  }
}
.sub-menu-button {
  display: flex;
  align-items: center;
  padding: 12px;
  transition: background-color 0.1s ease-in-out;
  border-radius: 7px;
  text-transform: capitalize;
  color: var(--nav-font-c);
  font-size: 15px;
  white-space: nowrap;
  &:hover,
  &.selected {
    background-color: #4b4a4a;
  }
  // &:not(:first-child) {
  //   margin-top: 10px;
  // }
}

/* second level */
.sub-menu-container {
  // padding-top: 10px;
  position: absolute;
  left: 0;
  top: 50px;
  padding-top: 12px;
  cursor: initial;
  z-index: 100;
  .hover & {
    z-index: 110;
  }
  .sub-menu-box {
    background-color: #3e3d3d;
    border-radius: 10px;
    padding: 8px;
    width: 100%;
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
      0px 2px 16px 1px rgba(0, 0, 0, 0.12);

    // top: 100%;
    // a {
    //   font-size: 1.25em; /* 20 */
    //   border-top: 1px solid #e15a1f;
    //   padding: 0.75em; /* 15 (20) */
    // }
    // li a:hover,
    // &:not(:hover) li.active a {
    //   background-color: #e15a1f;
    // }
  }
  // &:not(:first-child):hover ul {
  //   left: -1px;
  // }
}
</style>
