export const date = (input: string, option: { year: boolean } = { year: true }) => {
  return Intl.DateTimeFormat('default', {
    month: 'short',
    ...(option.year ? { year: 'numeric' } : {}),
    day: 'numeric',
  }).format(new Date(input))
}
export const capitalize = (input: string): string => (input ? `${input[0].toUpperCase()}${input.slice(1)}` : '')

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export const balanceFormat = (balance: any) => balance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
