import MainMenu from '@/views/MainMenu.vue'
import type { RouteRecordRaw } from 'vue-router'

const generalPostRoute: RouteRecordRaw = {
  path: '/general-post',
  name: 'general-post',
  redirect: { name: 'news-index' },
  meta: {
    menuTitle: 'General Post',
    hideOnMenu: true,
  },
  component: MainMenu,
  children: [
    {
      path: ':newsId(\\d+)/:seo?',
      name: 'general-post-detail',
      component: () => import('@/views/news/NewsDetail.vue'),
    },
  ],
}

export default generalPostRoute
