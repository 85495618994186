import type { RouteRecordRaw } from 'vue-router'
import BuyMerch from './buy-merch'
import Community from './community'
import Events from './events'
import Game from './game'
import GeneralPost from './general-post'
import News from './news'
import Ranking from './ranking'
import Support from './support'

const routes: RouteRecordRaw[] = [News, GeneralPost, Game, Ranking, Events, Community, Support, BuyMerch]

export default routes
