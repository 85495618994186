<template>
  <div class="error">
    <div class="monsters"></div>
    <div class="title">{{ title }}</div>
    <div class="desc">{{ desc }}</div>
    <RouterLink to="/">
      <MSButton>Back to Home</MSButton>
    </RouterLink>
  </div>
  <MediaPlatformLinks />
</template>
<script setup lang="ts">
const route = useRoute()

const isGeneral = computed(() => route.name?.toString().includes('general'))

const title = isGeneral.value ? 'An Unknown Error Occurred.' : '404 File Not Found'
const desc = isGeneral.value
  ? 'Please return to the previous page.'
  : 'The page you requested may have been moved or deleted.'
</script>
<style lang="scss" scoped>
$imgPath: '@/assets/imgs/';
.error {
  background-color: #333;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  .monsters {
    background: url(#{$imgPath}/monsters.png) no-repeat center center / auto 100%;
    width: 440px;
    height: 344px;
    margin-bottom: 25px;
  }
  .title {
    font-weight: bold;
    font-size: 50px;
  }
  .desc {
    font-size: 24px;
    font-weight: 100;
  }
  button {
    margin: 50px auto 0;
  }
}
</style>
