<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
const MobileOverlayMenu = defineAsyncComponent(() => import('@/components/NavigationBar/MobileOverlayMenu.vue'))
const showMenu = ref(false)
watchEffect(() => {
  document.documentElement.style.overflowY = showMenu.value ? 'hidden' : 'auto'
})

// watchEffect(() => {
//   if (!showMenu.value) expandedMenu.value = ''
// })
</script>
<template>
  <RouterLink to="/"><i-local-ms-logo class="ms-logo" /></RouterLink>
  <div class="mobile-items">
    <PlayNowButton class="mobile-cta" />
    <div class="button menu-btn" @click="showMenu = true">
      <i-local-mobile-menu />
    </div>
  </div>
  <MobileOverlayMenu v-if="showMenu" v-model:showMenu="showMenu" />
</template>

<style lang="scss" scoped>
.ms-logo {
  width: 96px;
  height: 65px;
  margin: -9px 23px 0;
}
.menu-btn {
  width: 43px;
  display: inline-flex;
  padding: 9px;
  margin: 0 13.5px 0 5px;
}
.mobile-cta {
  font-size: 12px;
  margin-right: 7px;
}

.mobile-items {
  display: flex;
  justify-items: center;
}
.button {
  display: inline-flex;
  border-radius: 10px;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #3e3d3d;
  }
}
</style>
