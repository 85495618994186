import axios from 'axios'
import router from '@/router'

const errorHandling = (): void => {
  router.push({
    name: 'error-general',
  })
}

const cdnApi = axios.create({
  baseURL: `//${import.meta.env.VITE_APP_CDN_HOST}/maplestory/cms/v1`,
})

const _convertObject = (data: { [key: string]: string } | any) => {
  if (typeof data !== 'object' || !data) return data
  Object.keys(data).forEach((key) => {
    const value = data[key]
    if (typeof value === 'object') {
      return _convertObject(value)
    }
    if (typeof value === 'string' && value.startsWith('/media')) {
      data[key] = `//${import.meta.env.VITE_APP_CDN_HOST.replace('pre.', '')}${value}`
    }
  })
}

cdnApi.interceptors.response.use((response) => {
  _convertObject(response.data)
  return response
}, errorHandling)

const gameApi = axios.create({
  baseURL: '/api/maplestory/no-auth/v1',
  headers: {
    'Content-type': 'application/json',
  },
  // withCredentials: true,
})
gameApi.interceptors.response.use((response) => response, errorHandling)

const gameApiWithAuth = axios.create({
  baseURL: '/api/maplestory/v1',
  headers: {
    'Content-type': 'application/json',
    // 'X-Arena-Membership-User-No': 25872985,
  },
  withCredentials: true,
})

const chatbotApi = axios.create({
  baseURL: '/api/maplestory/no-auth/patchnotes-bot',
  headers: { 'Content-Type': 'application/json' },
})

// chatbotApi.interceptors.response.use((response) => response, errorHandling)

export { cdnApi, gameApi, gameApiWithAuth, chatbotApi }
