<script setup lang="ts">
defineProps({
  menu: {
    type: Object as PropType<RouteRecordRaw>,
    required: true,
  },
  // this is for ga tracking
  parentName: {
    type: String,
    default: '',
  },
})

const route = useRoute()
</script>
<template>
  <a
    v-if="menu.path == '__'"
    :href="String(menu.redirect)"
    target="_blank"
    v-ga-event="`${parentName ? parentName + ' - ' : ''}${menu.meta?.menuTitle}`"
  >
    {{ menu.meta?.menuTitle }}
    <i-local-external-link class="external-link" />
  </a>
  <router-link
    v-else
    :class="{ selected: route.matched.some((record) => record.name == menu.name) }"
    :to="{ name: menu.name }"
    v-ga-event="`${parentName ? parentName + ' - ' : ''}${menu.meta?.menuTitle}`"
  >
    {{ menu.meta?.menuTitle }}
  </router-link>
</template>

<style lang="scss" scoped>
.external-link {
  margin-left: 10px;
  //   opacity: 0.7;
  width: 9px;
}

a {
  display: block;
  text-decoration: none;
  user-select: none;
  color: var(--nav-font-c);
  &.selected {
    pointer-events: none;
  }
}
</style>
