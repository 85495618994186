import type { RouteRecordRaw } from 'vue-router'
import MainMenu from '@/views/MainMenu.vue'

const rankingRoute: RouteRecordRaw = {
  path: '/rankings',
  name: 'rankings',
  component: MainMenu,
  redirect: { name: 'player-ranking' },
  meta: {
    menuTitle: 'Ranking',
    subTitle: 'CHECK YOUR RANKING',
    banners: ['news', 'sixth-job-skills', 'bean-brigade'],
  },
  children: [
    {
      path: ':region?/:type?/:filter?/:filter2?',
      name: 'player-ranking',
      component: () => import('@/views/ranking/index.vue'),
      meta: {
        menuTitle: 'Player Ranking',
        seo: {
          title: 'Rankings',
          description: 'Check your ranking to compare your progress against other players!',
        },
        gaPageType: 'ranking',
      },
    },
    {
      path: 'punchking',
      name: 'punchking',
      component: () => import('@/views/ranking/PunchKing.vue'),
      meta: {
        menuTitle: 'Punch King Rankings',
        fullPage: true,
        gaPageType: 'ranking - punchking',
      },
    },
  ],
}

export default rankingRoute
