<script setup lang="ts">
import { FlatMenuList } from '@/router/routes'
import { CSSProperties } from 'vue'

const { mobile } = useDisplay()
const route = useRoute()

const banners = computed(() => route.meta.banners ?? ['quick-start', 'server-status', 'classes-and-jobs']) // default value
const bannerMenus = computed(() =>
  FlatMenuList.filter((x) => banners.value.includes(String(x.name))).sort(
    (a, b) => banners.value.indexOf(String(a.name)) - banners.value.indexOf(String(b.name))
  )
)
const getBackgroundImageStyle = (id: string): Partial<CSSProperties> => {
  const url = new URL(
    `/src/assets/imgs/components/PageBanners/${id}${mobile.value ? '-mobile' : ''}.webp`,
    import.meta.url
  )
  return {
    backgroundImage: `url(${url})`,
  }
}
</script>
<template>
  <contentWrapper
    data-event="General Elements"
    data-section-number="98"
    data-section-name="Page Suggestion"
    v-ga-section-view="route.meta.menuTitle"
  >
    <PlayNowButton class="my-md-13 my-sm-8 my-4" />
    <div class="page-banners">
      <template v-for="menu in bannerMenus" :key="menu.name">
        <component
          :is="menu.path != '__' ? 'RouterLink' : 'a'"
          class="page-banners__banner"
          :class="menu.name"
          :to="{ name: menu.name }"
          :href="menu.path == '__' && menu.redirect"
          :target="menu.path == '__' ? '_blank' : ''"
          v-ga-event="menu.meta?.menuTitle"
        >
          <div :style="getBackgroundImageStyle(String(menu.name))">
            <span>{{ menu.meta?.menuTitle }}</span>
          </div>
        </component>
      </template>
    </div>
  </contentWrapper>
</template>
<style lang="scss" scoped>
.page-banners {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-banners__banner {
  width: 32%;
  height: pxToRem(170);
  display: inline-block;
  border-radius: pxToRem(10);
  max-width: 1600px;
  cursor: pointer;
  font-size: pxToRem(45);
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
  overflow: hidden;
  > div {
    display: flex;
    align-items: center;
    padding: 0 10.25rem 0 1rem;
    height: 100%;
    width: 100%;
    transition: all 0.3s;
    background-size: cover;
    will-change: transform;
  }
  &:hover {
    > div {
      transform: scale(1.05);
    }
  }
}
@media #{$tablet} {
  .page-banners__banner {
    width: pxToRemTablet(306);
    height: pxToRemTablet(106);
    font-size: pxToRemTablet(30);
    > div {
      padding: 0 4rem 0 0.25rem;
    }
  }
}
@media #{$mobile} {
  .page-banners {
    flex-direction: column;
  }
  .page-banners__banner {
    width: pxToRemMobile(552);
    height: pxToRemMobile(120);
    font-size: pxToRemMobile(42);
    border-radius: pxToRemMobile(15);
    > div {
      padding: 0 12rem 0 1rem;
    }
    &:not(:last-child) {
      margin-bottom: pxToRemMobile(15);
    }
  }
}
</style>
