import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import App from './App.vue'
// import i18n from './i18n'
import '@/assets/scss/base.scss'
import directives from './directives'
import router from './router'

const app = createApp(App)

const head = createHead()
app.use(head)
app.use(createPinia())
app.use(
  createVuetify({
    // https://vuetifyjs.com/en/features/display-and-platform/#breakpoint-conditionals
    display: {
      mobileBreakpoint: 'sm',
      thresholds: {
        sm: 601, // Tablet
        md: 1025, // PC
        lg: 1401, // large PC
        xl: 1641, // Max PC
      },
    },
  })
)
// app.use(i18n)
app.use(router).use(directives)

app.mount('#maplestory')
