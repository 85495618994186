import type { RouteRecordRaw } from 'vue-router'

const buyMerchRoute: RouteRecordRaw = {
  path: '__',
  name: 'buy-merch',
  redirect:
    '//merch.nexon.net/?_gl=1*iwtqb*_ga*MTUzNTkyNzU3Mi4xNjc3NzIwMDg1*_ga_W9NFQQ2Z9Q*MTY3ODgyODIxMy4xMS4xLjE2Nzg4MzI5ODMuMC4wLjA.',
  meta: {
    menuTitle: 'Buy Merch',
  },
}

export default buyMerchRoute
