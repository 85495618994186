import MainMenu from '@/views/MainMenu.vue'
import type { RouteRecordRaw } from 'vue-router'

const gameRoute: RouteRecordRaw = {
  path: '/game',
  name: 'game',
  component: MainMenu,
  meta: {
    menuTitle: 'Game',
  },
  children: [
    {
      path: 'classes-and-jobs',
      name: 'classes-and-jobs',
      component: () => import('@/views/game/ClassesJobs/ClassesJobsList.vue'),
      meta: {
        menuTitle: 'Classes & Jobs',
        subTitle: 'Each class has their own story to tell',
        banners: ['explore-maplestory', 'quick-start', 'world-map'],
        seo: {
          description:
            'Each character and class has their own questline and tale to tell - try them all to get the full story!',
        },
        gaPageType: 'heroes - all',
      },
    },
    {
      path: 'classes-and-jobs/:classID/:className/:seo?',
      name: 'classes-and-jobs-detail',
      component: () => import('@/views/game/ClassesJobs/ClassesJobsDetail.vue'),
      meta: {
        menuTitle: 'Classes & Jobs',
        hideOnMenu: true,
        fullPage: true,
        banners: ['explore-maplestory', 'quick-start', 'world-map'],
        seo: {
          description:
            'Each character and class has their own questline and tale to tell - try them all to get the full story!',
        },
        gaPageType: 'heroes - detail',
        noscroll: true,
      },
    },
    {
      path: '6th-job-skills',
      name: 'sixth-job-skills',
      component: () => import('@/views/game/6thJobSkills/6thJobSkills.vue'),
      meta: {
        menuTitle: '6th Job Skills',
        subTitle: 'unlock your maximum potential with 6th job',
        banners: ['classes-and-jobs', 'news', 'maple-guides'],
        seo: {
          title: '6th Job',
          description: "Unlock your character's maximum potential with powerful 6th Job skills!",
        },
        gaPageType: 'heroes - 6th job',
      },
    },
    {
      path: '',
      name: 'em-mw-qs',
      component: () => import('@/views/game/ExploreMaplestoryMenu.vue'),
      meta: {
        gaPageType: 'introduction',
      },
      children: [
        {
          path: 'explore-maplestory',
          name: 'explore-maplestory',
          component: () => import('@/views/game/ExploreMaplestory.vue'),
          meta: {
            menuTitle: 'Explore MapleStory',
            subTitle: "explore maple world's rich history",
            banners: ['quick-start', 'world-map', 'classes-and-jobs'],
            seo: {
              title: 'Explore',
              description: 'Explore the stories, bosses, and heroes of Maple World and beyond!',
            },
          },
        },
        {
          path: 'world-map',
          name: 'world-map',
          component: () => import('@/views/game/WorldMap.vue'),
          meta: {
            menuTitle: 'World Map',
            subTitle: 'Immerse yourself in diverse landscapes',
            banners: ['explore-maplestory', 'quick-start', 'classes-and-jobs'],
            seo: {
              description: 'From floating cities to giant submarines, there are so many diverse landscapes to explore!',
            },
          },
        },
        {
          path: 'quick-start',
          name: 'quick-start',
          component: () => import('@/views/game/QuickStart.vue'),
          meta: {
            menuTitle: 'Quick Start',
            subTitle: 'welcome to maplestory',
            banners: ['explore-maplestory', 'world-map', 'classes-and-jobs'],
            seo: {
              description: 'New to MapleStory? Check out this guide to get you started!',
            },
          },
        },
      ],
    },
    {
      path: 'maple-guides/:category?',
      name: 'maple-guides',
      component: () => import('@/views/game/MapleGuide/index.vue'),
      redirect: { name: 'maple-guide-list' },
      meta: {
        menuTitle: 'Maple Guides',
        subTitle: 'quick courses in gameplay elements',
        banners: ['news', 'bean-brigade', 'player-ranking'],
        seo: {
          title: 'Guides',
          description: 'Check out the Maple Guides for tips and quick courses in different elements of gameplay!',
        },
        gaPageType: 'information',
      },
      children: [
        {
          path: '',
          name: 'maple-guide-list',
          component: () => import('@/views/game/MapleGuide/GuideList.vue'),
        },
        {
          path: ':id(\\d+)/:seoTitle?',
          name: 'maple-guide-detail',
          component: () => import('@/views/game/MapleGuide/GuideDetail.vue'),
        },
      ],
    },
    {
      path: 'random-box-rates',
      name: 'random-box-rates',
      component: () => import('@/views/news/NewsDetail.vue'),
      meta: {
        menuTitle: 'Random Box Rates',
        cdnApiPath: `/general-posts/${import.meta.env.VITE_APP_CDN_HOST == 'test.g.nexonstatic.com' ? 3982 : 5805}`,
        banners: ['news', 'player-ranking', 'bean-brigade'],
        seo: {
          title: 'Box Rates',
          description:
            'Look up the reward rates for different types of boxes that give you a surprise reward from a master list!',
        },
        gaPageType: 'information',
      },
    },
    {
      path: '__',
      name: 'wiki',
      redirect: '//maplestorywiki.net/w/MapleStory_Wiki',
      meta: {
        menuTitle: 'Wiki',
      },
    },
  ],
}

export default gameRoute
